class Util {
  static isObject(item: any) {
    return item && typeof item === 'object' && !Array.isArray(item)
  }

  static mergeDeep(target: object, source: object) {
    let output = Object.assign({}, target)
    if (this.isObject(target) && this.isObject(source)) {
      Object.keys(source).forEach(key => {
        if (this.isObject(source[key])) {
          if (!(key in target)) Object.assign(output, { [key]: source[key] })
          else output[key] = this.mergeDeep(target[key], source[key])
        } else {
          Object.assign(output, { [key]: source[key] })
        }
      })
    }
    return output
  }

  static map(
    n: number,
    start1: number,
    stop1: number,
    start2: number,
    stop2: number
  ): number {
    return ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2
  }

  static debounce(func: Function, wait: number, immediate: boolean) {
    let timeout: number | null
    return function () {
      const context = this
      const args = arguments
      const later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      const callNow = immediate && !timeout
      window.clearTimeout(timeout)
      timeout = window.setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }

  static getMousePosition(e?: MouseEvent | Event | any) {
    let posX = 0
    let posY = 0

    if (!e) e = window.event

    if (e.pageX || e.pageY) {
      posX = e.pageX
      posY = e.pageY
    } else if (e.clientX || e.clientY) {
      posX = e.clientX - this.docScrolls().x
      posY = e.clientY - this.docScrolls().y
    }

    return {
      x: posX,
      y: posY,
    }
  }

  static docScrolls() {
    return {
      //x: document.body.scrollLeft + document.documentElement.scrollLeft,
      //y: document.body.scrollTop + document.documentElement.scrollTop,
      x: 0,
      y: 0,
    }
  }
}

export default Util
