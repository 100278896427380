// Preloader

interface Options {
  video?: {
    autoplay?: boolean
    muted?: boolean
    loop?: boolean
  }
  timeup: number
  onStart?: Function
  onProgress?: Function
}

interface FileOption {
  src: string
  target: string
  /**
   * 読み込み完了後の処理について
   * bg: style.backgroundImage
   * img: img.src
   */
  type: 'bg' | 'img'
}

interface ParsedFileOption extends FileOption {
  path: string
  /**
   * 拡張子から判別する
   */
  asset: 'image' | 'video' | null
}

class Preloader {
  public files: FileOption[]
  // 読み込まれたファイルの数
  public count: number
  public total: number
  // 進捗
  public progress: number
  public progressStep: number
  private _options: Options
  private _aborts: AbortController[]

  constructor(files: FileOption[], _options: Options) {
    const defaultOptions = {
      video: {
        autoplay: true,
        muted: true,
        loop: true,
      },
      timeup: 30, //second
      onStart: () => {},
      onProgress: () => {},
    }
    this.count = 0
    this.total = 0
    this.files = files
    this.progress = 0

    this._aborts = []

    this._options = { ...defaultOptions, ..._options }
  }

  public start() {
    const files = this._parseFiles()

    if (files) {
      this.total = files.length
      this.progressStep = 100 / this.total / 3

      if (this._options.onStart) {
        this._options.onStart({
          total: this.total,
        })
      }
      return this._loadFiles(files)
    }
  }

  public stop() {
    if (this._aborts.length) {
      this._aborts.forEach(abort => abort.abort())
    }
  }

  // count increment
  private _countUp() {
    this.count++
  }

  private _countDown() {
    this.count--
  }

  private _progressUp(val = 1) {
    this.progress = this.progress + this.progressStep * val
    if (this._options.onProgress) {
      this._options.onProgress(Math.round(this.progress))
    }
  }

  private _parseFiles(): ParsedFileOption[] | null {
    if (!this.files.length) {
      return null
    }

    return this.files.map(option => {
      let path = option.src
      let asset = ''

      if (!/^(http|https)/.test(option.src)) {
        const sep = option.src.charAt(0) === '/' ? '' : '/'
        path = `${window.location.origin}${sep}${option.src}`
      }

      // type check
      const expStr = option.src.match(/\.(\w{3,4})$/)

      if (expStr && expStr.length) {
        switch (expStr[1]) {
          case 'jpg':
          case 'png':
            asset = 'image'
            break
          case 'mp4':
            asset = 'video'
        }
      }

      this._countUp()

      return {
        asset,
        path,
        ...option,
      } as ParsedFileOption
    })
  }

  private async _loadFiles(parseFiles: ParsedFileOption[]) {
    await Promise.all(
      parseFiles.map(option => {
        return new Promise(async resolve => {
          this._progressUp()

          switch (option.asset) {
            case 'video':
              try {
                const abort = new AbortController()
                this._aborts.push(abort)
                const res = await fetch(option.path, {
                  signal: abort.signal,
                })
                const blob = await res.blob()
                this._progressUp()
                const url = (window.URL || window.webkitURL).createObjectURL(
                  blob
                )
                this._setVideo(url, option.target)
              } catch (e) {
                console.error(`Failed to load video file - ${option.path}`)
              }
              this._progressUp()
              this._countDown()
              resolve(option)
              break
            case 'image':
              let img = new Image()
              img.src = option.path

              img.onload = () => {
                this._progressUp()
                switch (option.type) {
                  case 'bg':
                    this._setBg(option.path, option.target)
                  case 'img':
                    this._setImg(option.path, option.target)
                }

                this._progressUp()
                this._countDown()
                resolve(option)
              }
              img.onerror = err => {
                this._progressUp(2)
                this._countDown()
                console.error(`Failed to load image file - ${option.path}`)
                resolve(option)
              }
          }
        })
      })
    )
  }

  private _setBg(url: string, target: string) {
    const targets = window.document.querySelectorAll<HTMLElement>(target)
    if (targets.length) {
      targets.forEach(target => {
        target.style.backgroundImage = `url(${url})`
      })
    }
  }

  private _setImg(url: string, target: string) {
    const targets = window.document.querySelectorAll<HTMLImageElement>(
      `img[data-src="${target}"]`
    )
    if (targets.length) {
      targets.forEach(target => {
        target.src = url
      })
    }
  }

  private _setVideo(url: string, target: string) {
    const targets = window.document.querySelectorAll<HTMLElement>(target)
    const video = document.createElement('video')
    video.autoplay = this._options.video?.autoplay || false
    video.muted = this._options.video?.muted || true
    video.loop = this._options.video?.loop || true
    video.src = url
    video.play()
    targets.forEach(target => target.appendChild(video))
  }
}

export default Preloader
