import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import Slider from './js/slider.ts'
import Preloader from './js/preloader.ts'
import Progress from './js/progress.ts'
// import sectionAnimation from './js/section.js'
import sectionAnimation from './js/section.ts'
import Modal from './js/modal.ts'

gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(ScrollTrigger)

// scrolltrigger
ScrollTrigger.defaults({
  toggleActions: 'restart pause resume pause',
  scroller: document.body,
})

// ScrollTo ---
// Detect if a link's href goes to the current page
function getSamePageAnchor(link) {
  if (
    link.protocol !== window.location.protocol ||
    link.host !== window.location.host ||
    link.pathname !== window.location.pathname ||
    link.search !== window.location.search
  ) {
    return false
  }

  return link.hash
}

// Scroll to a given hash, preventing the event given if there is one
function scrollToHash(hash, e) {
  const elem = hash ? document.querySelector(hash) : false
  if (elem) {
    if (e) e.preventDefault()
    gsap.to(document.body, { scrollTo: elem })
  }
}

document.querySelectorAll('.site-header a[href]').forEach(a => {
  a.addEventListener('click', e => {
    scrollToHash(getSamePageAnchor(a), e)
  })
})

scrollToHash(window.location.hash)

// Keyvisual animation ---
const kv = gsap.timeline({
  scrollTrigger: {
    trigger: '#hero',
    start: 'top top',
    end: 'bottom top',
    scrub: true,
  },
})
gsap.utils.toArray('.parallax').forEach(layer => {
  const depth = layer.dataset.depth
  const amount = window.innerWidth < 768 ? 2 : 1
  const movement = -((layer.offsetHeight / amount) * depth)
  kv.to(layer, { y: movement, ease: 'none' }, 0)
})

// セクションアニメ
const section = new sectionAnimation()

section.init()

// ローディング
const progress = new Progress({
  onComplete: () => {
    section.start()

    const guidelineModal = new Modal(document.getElementById('guideline'))

    document.getElementById('btn-guideline').addEventListener('click', e => {
      e.preventDefault()
      guidelineModal.show()
    })
  },
})

// プリローダー
const preloader = new Preloader(
  [
    { src: '/images/kv/1.jpg', target: '.layer-bg', type: 'bg' },
    { src: '/images/kv/2.png', target: '.layer-1', type: 'bg' },
    { src: '/images/kv/3.png', target: '.layer-2', type: 'bg' },
    { src: '/images/kv/4.png', target: '.layer-3', type: 'bg' },
    { src: '/images/art/1.jpg', target: 'art-1', type: 'img' },
    { src: '/images/art/2.jpg', target: 'art-2', type: 'img' },
    { src: '/images/art/3.jpg', target: 'art-3', type: 'img' },
    { src: '/images/art/4.jpg', target: 'art-4', type: 'img' },
    { src: '/images/xenoscrypto.jpg', target: 'xeno', type: 'img' },
    { src: '/images/object.jpg', target: 'object', type: 'img' },
  ],
  {
    onStart: () => {
      console.log('✨ Welcome to MGU ✨')
    },
    onProgress: value => {
      progress.updateCounter(value)
    },
  }
)

preloader.start().then(() => {
  new Slider(document.querySelector('#slider'))

  ScrollTrigger.create({
    start: 'top -80',
    end: 99999,
    toggleClass: {
      className: 'scrolled',
      targets: '.site-header',
    },
  })

  progress.hide()
})
