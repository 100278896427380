/**
 * Moustafa Jazzar: GSAP Slider
 * https://codepen.io/MoustafaJazzar/pen/PBMgNg
 *
 * fixed GSAP(v3) support
 * remove Title DOM
 */

import { gsap, Expo, Power1 } from 'gsap'
import Util from './util'

type Transform = {
  x?: number
  y?: number
  z?: number
}
interface Options {
  rotate?: Transform
  translate?: Transform
  lock?: boolean
  speed?: number
}

class Tilt {
  constructor(private _el: HTMLElement, private _options: Options) {
    const defaultOptions = {
      rotate: { x: 0, y: 0, z: 0 },
      translate: { x: 0, y: 0, z: 0 },
      lock: false,
      speed: 0,
    }
    this._options = Util.mergeDeep(defaultOptions, _options)
    this._bindEvents()
  }

  private _bindEvents() {
    const opt = this._options

    this._el.addEventListener('mousemove', (e: MouseEvent) => {
      const mousePos = Util.getMousePosition(e)
      const bounds = this._el.getBoundingClientRect()
      const docScrolls = Util.docScrolls()
      const relMousePos = {
        x: Math.round(mousePos.x - bounds.left - docScrolls.x),
        y: Math.round(mousePos.y - bounds.top - docScrolls.y),
      }

      const translation = {
        x: Math.round(
          Util.map(
            relMousePos.x,
            0,
            bounds.width,
            -opt.translate.x,
            opt.translate.x
          )
        ),
        y: Math.round(
          Util.map(
            relMousePos.y,
            0,
            bounds.height,
            -opt.translate.y,
            opt.translate.y
          )
        ),
        z: Math.round(
          Util.map(
            relMousePos.y,
            0,
            bounds.height,
            -opt.translate.z,
            opt.translate.z
          )
        ),
      }
      const rotation = {
        x: Math.round(
          Util.map(relMousePos.y, 0, bounds.width, -opt.rotate.x, opt.rotate.x)
        ),
        y: Math.round(
          Util.map(relMousePos.x, 0, bounds.height, opt.rotate.y, -opt.rotate.y)
        ),
        z: Math.round(
          Util.map(relMousePos.x, 0, bounds.height, -opt.rotate.z, opt.rotate.z)
        ),
      }
      this._animate({ translation, rotation })
    })

    if (opt.lock) {
      this._el.addEventListener('mouseleave', e => {
        gsap.to(this._el, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
          rotationX: 0,
          rotationY: 0,
          rotationZ: 0,
          ease: Power1.easeOut,
        })
      })
    }
  }

  private _animate(ref) {
    gsap.to(this._el, {
      duration: this._options.speed / 1000,
      x: ref.translation.x,
      y: ref.translation.y,
      z: ref.translation.z,
      rotationX: ref.rotation.x,
      rotationY: ref.rotation.y,
      rotationZ: ref.rotation.z,

      ease: Power1.easeOut,
    })
  }
}

class Slide {
  DOM: any
  config: any
  constructor(el: HTMLElement) {
    this.DOM = {}
    this.DOM.el = el
    this.DOM.wrap = el.querySelector('.slide-wrapper')
    this.DOM.imgWrapper = el.querySelector('.img-wrapper')
    this.config = {
      animation: {
        duration: 1,
        ease: Expo.easeInOut,
      },
      tiltOptions: {
        translate: {
          x: -10,
          y: -10,
        },
        speed: 800,
      },
    }

    new Tilt(el.querySelector('.slide-img'), this.config.tiltOptions)
  }
  setCurrent(isCurrent = true) {
    this.DOM.el.classList[isCurrent ? 'add' : 'remove']('current')
  }
  hide(direction) {
    return this.toggle('hide', direction)
  }
  show(direction) {
    this.DOM.el.style.zIndex = 11
    return this.toggle('show', direction)
  }
  toggle(action, direction) {
    return new Promise(resolve => {
      if (action === 'show') {
        gsap.to(this.DOM.wrap, {
          duration: this.config.animation.duration,
          ease: this.config.animation.ease,
          startAt: {
            x: direction === 'right' ? '100%' : '-100%',
          },
          x: '0%',
        })
      }

      gsap.to(this.DOM.imgWrapper, {
        duration: this.config.animation.duration,
        ease: this.config.animation.ease,
        startAt:
          action === 'hide'
            ? {}
            : {
                x: direction === 'right' ? '-100%' : '100%',
                scale: 1.1,
              },
        x: '0%',
        scale: action === 'hide' ? 1.1 : 1,
        onStart: () => {
          this.DOM.imgWrapper.style.transformOrigin =
            action === 'hide'
              ? direction === 'right'
                ? '100% 50%'
                : '0% 50%'
              : direction === 'right'
              ? '0% 50%'
              : '100% 50%'
          this.DOM.el.style.opacity = 1
        },
        onComplete: () => {
          this.DOM.el.style.zIndex = 9
          this.DOM.el.style.opacity = action === 'hide' ? 0 : 1
          resolve(true)
        },
      })
    })
  }
}

class Navigation {
  DOM: any
  bullets: HTMLElement[]
  settings: any
  constructor(el, settings) {
    this.DOM = {}
    this.DOM.el = el
    this.bullets = []
    this.settings = {
      active: 0,
      onClick: () => false,
    }
    Object.assign(this.settings, settings)
    this.init()
  }
  init() {
    for (const bullet of this.DOM.el.querySelectorAll('.bullet')) {
      this.bullets.push(bullet)
    }

    this.bullets[this.settings.active].classList.add('current')
    this.bindEvents()
  }

  bindEvents() {
    this.bullets.forEach((bullet, idx) => {
      bullet.addEventListener('click', () => {
        this.settings.onClick(idx)
      })
    })
  }

  setCurrent(idx) {
    this.bullets.forEach(bullet => {
      bullet.classList.remove('current')
    })
    this.bullets[idx].classList.add('current')
  }
}

class Slider {
  DOM: any
  slides: Slide[]
  settings: any
  navigation: Navigation
  isAnimating: boolean
  autoId: number

  constructor(el: HTMLElement, settings?: any) {
    this.DOM = {}
    this.DOM.el = el
    this.slides = []
    this.autoId = 0

    this.settings = {
      currentSlide: 0,
    }
    Object.assign(this.settings, settings)
    this.init()
  }
  init() {
    this.navigation = new Navigation(document.querySelector('#navigation'), {
      active: this.settings.currentSlide,
      onClick: (idx: number) => {
        this.navigate(idx)
        this.autoId = idx
      },
    })

    for (const slide of this.DOM.el.querySelectorAll('.slide')) {
      this.slides.push(new Slide(slide))
    }
    this.slides[this.settings.currentSlide].setCurrent()

    let intervalID = window.setInterval(() => {
      this.navigate(this.autoId)
      this.autoId++
      if (this.autoId >= this.slides.length) {
        this.autoId = 0
      }
    }, 4000)
  }

  async navigate(idx) {
    if (this.isAnimating || idx === this.settings.currentSlide) return
    this.isAnimating = true

    const direction = idx > this.settings.currentSlide ? 'right' : 'left'

    this.navigation.setCurrent(idx)
    await Promise.all([
      this.slides[this.settings.currentSlide].hide(direction),
      this.slides[idx].show(direction),
    ])
    this.slides[this.settings.currentSlide].setCurrent(false)
    this.settings.currentSlide = idx
    this.slides[this.settings.currentSlide].setCurrent()
    this.isAnimating = false
  }
}

export default Slider

// const options = { currentSlide: 3 };
// const slider = new Slider(sliderEl, options);
