// Modal
import { gsap } from 'gsap'

class Modal {
  public el: HTMLElement
  public closeBtn: NodeListOf<HTMLElement>
  public backdrop: HTMLElement
  public dialog: HTMLElement
  public openTl: GSAPTimeline
  public closeTl: GSAPTimeline
  public isShow: boolean

  constructor(el: HTMLElement) {
    this.el = el
    this.backdrop = this.el.querySelector('.modal-backdrop')
    this.closeBtn = this.el.querySelectorAll('.modal-close')
    this.dialog = this.el.querySelector('.modal-dialog')
    this.isShow = false

    this.closeBtn.forEach(el => {
      el.addEventListener('click', e => {
        e.preventDefault()
        this.hide()
      })
    })

    this.backdrop.addEventListener('click', e => {
      e.preventDefault()
      this.hide()
    })

    this.initOpenTl()
    this.initCloseTl()
  }

  // Open Animation
  private initOpenTl() {
    this.openTl = gsap.timeline({
      paused: true,
      onStart: () => {
        this.el.style.display = 'block'
      },
      onComplete: () => {
        this.isShow = true
      },
    })

    this.openTl.to(this.backdrop, {
      duration: 0.2,
      opacity: 1,
    })

    this.openTl.fromTo(
      this.dialog,
      {
        opacity: 0,
        scale: 0.9,
      },
      {
        duration: 0.4,
        opacity: 1,
        scale: 1,
        ease: 'elastic.out(1, 0.5)',
      },
      '<0.2'
    )
  }

  private initCloseTl() {
    this.closeTl = gsap.timeline({
      paused: true,
      onComplete: () => {
        this.isShow = false
        this.el.style.display = 'none'
      },
    })

    this.closeTl.to(this.dialog, {
      opacity: 0,
      scale: 0.9,
      duration: 0.3,
      ease: 'expo.out',
    })

    this.closeTl.to(
      this.backdrop,
      {
        duration: 0.2,
        opacity: 1,
      },
      '<0.2'
    )
  }

  public show() {
    this.openTl.restart()
  }

  public hide() {
    this.closeTl.restart()
  }
}

export default Modal
