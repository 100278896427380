// Section ScrollTrigger
// セクションごとにタイムライン生成とかする
import { gsap } from 'gsap'

interface SectionElement extends HTMLElement {
  hasFigure: boolean | undefined | null
  quoteLength: number | undefined | null
}

interface QuoteElement extends HTMLElement {
  chars: HTMLElement[]
  textLength: number
}

class SectionAnimation {
  public sections: NodeListOf<SectionElement>
  public tls: GSAPTimeline[]
  public master: GSAPTimeline

  constructor() {
    this.sections = document.querySelectorAll<SectionElement>('section')
  }

  init() {
    this.sections.forEach(section => {
      this.initFigure(section)
      this.initQuotes(section)
      this.initButton(section)
    })
  }

  /**
   * アニメーション開始
   */
  public start() {
    this.sections.forEach(section => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          toggleActions: 'restart pause resume reset',
          // toggleActions: 'play none none none',
          start: 'top 50%',
          // once: true,
        },
      })

      this.startFigure(section, tl)
      this.startQuotes(section, tl)
      this.startButton(section, tl)
    })
  }

  /**
   * figure要素初期化
   * @param section section要素
   */
  private initFigure(section: SectionElement) {
    const figure = section.querySelector('figure')

    if (figure) {
      section.hasFigure = true
      const figureCover1 = document.createElement('div')
      const figureCover2 = document.createElement('div')
      figureCover1.classList.add('figure-cover')
      figureCover1.classList.add('figure-cover--1')
      figureCover2.classList.add('figure-cover')
      figureCover2.classList.add('figure-cover--2')
      figure.appendChild(figureCover1)
      figure.appendChild(figureCover2)
    }
  }

  /**
   * figure要素アニメーション
   * @param section section要素
   * @param tl
   */
  private startFigure(section: SectionElement, tl: GSAPTimeline) {
    const figureCover1 = section.querySelector('.figure-cover--1')
    const figureCover2 = section.querySelector('.figure-cover--2')

    if (figureCover1) {
      tl.to(
        figureCover1,
        {
          duration: 0.3,
          ease: 'circ.inOut',
          x: '100%',
        },
        0
      )
    }

    if (figureCover2) {
      tl.to(
        figureCover2,
        {
          duration: 0.3,
          ease: 'circ.inOut',
          x: '-100%',
        },
        0.3
      )
    }
  }

  /**
   * 改行アニメーション
   * @param section section要素
   * @param tl
   */
  private startQuotes(section: SectionElement, tl: GSAPTimeline) {
    const quotes = section.querySelectorAll<QuoteElement>('.quote')

    quotes.forEach((quote, index) => {
      tl.to(
        quote.chars,
        {
          duration: 0.6,
          ease: 'circ.out',
          rotate: 0,
          alpha: 1,
          scale: 1,
          z: 0,
          stagger: 0.02,
          //filter: `blur(30px)`,
        },
        index ? `<${quote.textLength * 0.02}` : section.hasFigure ? 0.3 : 0
      )
    })
  }

  /**
   * 段落の処理
   * @param section section要素
   * @returns
   */
  private initQuotes(section: SectionElement) {
    const quotes = section.querySelectorAll<QuoteElement>('.quote')

    section.quoteLength = quotes.length

    if (!quotes.length) {
      return
    }

    // 文章を段落ごとに文字分割処理してタイムラインに登録

    quotes.forEach(quote => {
      const innerText = quote.innerText
      const trimText = innerText.replace(/\s*/gm, '')
      quote.textLength = trimText.length
      quote.setAttribute('aria-label', trimText)
      quote.chars = []
      quote.innerText = ''

      // 改行で分割
      const lines = innerText.split(/[\n\r]/gm)

      lines.forEach(line => {
        const texts = line.split('')
        const li = document.createElement('span')
        li.classList.add('line')
        quote.appendChild(li)

        texts.forEach(text => {
          const el = document.createElement('span')
          el.classList.add('word')
          el.innerText = text
          li.appendChild(el)
          quote.chars.push(el)
        })
      })

      gsap.set(quote.chars, {
        alpha: 0,
        scale: 0,
        z: 0.01,
        rotate: 20,
      })
    })
  }

  /**
   * ボタンあにめーしょん
   * @param section section要素
   * @param tl
   */
  private startButton(section: SectionElement, tl: GSAPTimeline) {
    const buttons = section.querySelectorAll('.btn')

    if (buttons.length) {
      tl.to(
        buttons,
        {
          duration: 0.6,
          ease: 'circ.out',
          rotate: 0,
          alpha: 1,
          scale: 1,
          z: 0,
          stagger: 0.1,
        },
        `-=${section.quoteLength * 0.1}`
      )
    }
  }

  /**
   * ボタン初期化
   * @param section section要素
   */
  private initButton(section: SectionElement) {
    const buttons = section.querySelectorAll('.btn')

    // ボタン
    if (buttons.length) {
      gsap.set(buttons, {
        alpha: 0,
        scale: 0,
        z: 0.01,
        rotate: 20,
      })
    }
  }
}

export default SectionAnimation
